import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  root: {},
  header: {
    paddingBottom: 8
  },
  content: {
    paddingTop: 8
  },
  title: {
    lineHeight: 1.2,
    fontSize: '1.15rem',
    marginBottom: '0.5rem'
  }
}));
