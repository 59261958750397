import { createMuiTheme } from '@material-ui/core/styles';
import NotoSans400Italic from 'Assets/fonts/Noto_Sans/NotoSans-Italic.woff2';
import NotoSans700Italic from 'Assets/fonts/Noto_Sans/NotoSans-BoldItalic.woff2';
import NotoSans400Normal from 'Assets/fonts/Noto_Sans/NotoSans-Regular.woff2';
import NotoSans700Normal from 'Assets/fonts/Noto_Sans/NotoSans-Bold.woff2';
import MARSNEVENEKSK from 'Assets/fonts/MARSNEVENEKSK/MARSNEVENEKSK-Regular.woff2';

const notoSans400Italic = {
  fontFamily: 'Noto Sans',
  fontStyle: 'italic',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Noto Sans Italic'),
    local('NotoSans-Italic'),
    url(${NotoSans400Italic}) format('woff2')
  `,
  unicodeRange:
    'U+0370-03FF, U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD'
};

const notoSans700Italic = {
  fontFamily: 'Noto Sans',
  fontStyle: 'italic',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `
    local('Noto Sans Bold Italic'),
    local('NotoSans-BoldItalic'),
    url(${NotoSans700Italic}) format('woff2')
  `,
  unicodeRange:
    'U+0370-03FF, U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD'
};

const notoSans400Normal = {
  fontFamily: 'Noto Sans',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Noto Sans'),
    local('NotoSans'),
    url(${NotoSans400Normal}) format('woff2')
  `,
  unicodeRange:
    'U+0370-03FF, U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD'
};

const notoSans700Normal = {
  fontFamily: 'Noto Sans',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `
    local('Noto Sans Bold'),
    local('NotoSans-Bold'),
    url(${NotoSans700Normal}) format('woff2')
  `,
  unicodeRange:
    'U+0370-03FF, U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD'
};

const marsneveneksk = {
  fontFamily: 'MARSNEVENEKSK',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `
    local('MARSNEVENEKSK'),
    url(${MARSNEVENEKSK}) format('woff2')
  `
};

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#191919'
    }
  },
  typography: {
    fontFamily: ['Noto Sans']
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [
          notoSans400Italic,
          notoSans700Italic,
          notoSans400Normal,
          notoSans700Normal,
          marsneveneksk
        ]
      }
    }
  }
});

export default theme;
