import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'block',
  },
  mock: {
    backgroundColor: '#e1ebfa',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '2rem',
    width: '100%',
    height: '18rem',
  },
  adsbygoogle: {
    display: 'block',
  },
}));

function Ad({ mock }) {
  const classes = useStyles();

  useEffect(() => {
    if (!mock) {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
  }, [mock]);
  return (
    <div className={classes.root}>
      {mock ? (
        <div className={classes.mock}>AD UNIT</div>
      ) : (
        <ins
          className={clsx(classes.adsbygoogle, 'adsbygoogle')}
          data-adtest="on"
          data-ad-client="ca-pub-5664746648043267"
          data-ad-slot="6944573683"
          data-ad-format="auto"
          data-full-width-responsive="true"
        />
      )}
    </div>
  );
}

Ad.propTypes = {
  mock: PropTypes.bool,
};

Ad.defaultProps = {
  mock: false,
};

export default React.memo(Ad);
