import superagent from 'superagent';

export async function getListings(params = {}) {
  const { before } = params;
  const { body, headers } = await superagent
    .get('https://api.nairepaok.gr/listings')
    .query({ ...(before && { before }) });

  return { listings: body, meta: headers };
}
