import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import App from 'Components/App';
import theme from './app_theme';

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Router>
      <Switch>
        <Route path="/:category?">
          <App />
        </Route>
      </Switch>
    </Router>
  </ThemeProvider>,
  document.getElementById('root')
);
