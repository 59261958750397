import {
  differenceInMinutes,
  isToday,
  isYesterday,
  format,
  formatDistanceToNow,
  formatRelative
} from 'date-fns';
import { el } from 'date-fns/locale';

export default date => {
  if (diffIsLessThanAMinute(date)) {
    return formatDistanceToNow(date, { locale: el });
  }

  if (isTodayOrYesterday(date)) {
    return formatRelative(date, Date.now(), { locale: el });
  }

  return format(date, 'dd/MM/yy hh:mm aaaa', { locale: el });
};

const diffIsLessThanAMinute = date => differenceInMinutes(Date.now(), date) < 1;

const isTodayOrYesterday = date => isToday(date) || isYesterday(date);
