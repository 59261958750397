import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import SourceLogo from 'Components/SourceLogo';
import Typography from '@material-ui/core/Typography';
import useStyles from './useStyles';
import calcDateTimeLabel from 'Library/utils/datetime';
import ellipsize from 'ellipsize';
import striptags from 'striptags';
import { decodeHTML } from 'entities';

export default function NewsItem({
  title,
  description,
  source,
  link,
  published_at,
}) {
  const classes = useStyles();

  const itemDate = calcDateTimeLabel(published_at * 1000);

  const itemDescription = useMemo(
    () => ellipsize(decodeHTML(striptags(description)), 200),
    [description]
  );

  return (
    <Card className={classes.root}>
      <CardActionArea href={link} target="_blank">
        <CardHeader
          className={classes.header}
          avatar={<SourceLogo source={source} />}
          title={source}
          subheader={itemDate}
        />
        <CardContent className={classes.content}>
          <Typography component="h2" className={classes.title}>
            {title}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {itemDescription}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

NewsItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  published_at: PropTypes.number.isRequired,
  link: PropTypes.string.isRequired,
};

NewsItem.defaultProps = {};
