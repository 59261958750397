import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import SportsSoccerIcon from '@material-ui/icons/SportsSoccer';
import SportsBasketballIcon from '@material-ui/icons/SportsBasketball';
import SportsHandballIcon from '@material-ui/icons/SportsHandball';
import SubjectIcon from '@material-ui/icons/Subject';
import SportsVolleyballIcon from '@material-ui/icons/SportsVolleyball';
import { Link, matchPath, useLocation } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  header: {
    position: 'relative'
  },
  title: {
    fontFamily: 'MARSNEVENEKSK',
    color: '#000000',
    fontSize: 95,
    textAlign: 'center',
    margin: 0,
    fontWeight: 500,

    [theme.breakpoints.down('sm')]: {
      fontSize: 65
    }
  },
  burgerButton: {
    position: 'absolute',
    top: '50%',
    left: 0,
    transform: 'translateY(-50%)'
  },
  paper: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  listItemIcon: {
    color: theme.palette.primary.contrastText
  },
  drawerMenu: {
    width: 250
  },
  menu: {
    justifyContent: 'center',
    '& > * + *': {
      marginLeft: theme.spacing(1)
    }
  }
}));

export default function Header() {
  const classes = useStyles();
  const location = useLocation();

  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const renderCategoryIcon = cat => {
    if (cat === 'all') {
      return <AllInclusiveIcon />;
    } else if (cat === 'soccer') {
      return <SportsSoccerIcon />;
    } else if (cat === 'basketball') {
      return <SportsBasketballIcon />;
    } else if (cat === 'volleyball') {
      return <SportsVolleyballIcon />;
    } else if (cat === 'handball') {
      return <SportsHandballIcon />;
    } else {
      return <SubjectIcon />;
    }
  };

  const getMenuButtonProps = path => ({
    component: Link,
    to: path,
    variant:
      matchPath(location.pathname, { path, exact: true }) === null
        ? 'text'
        : 'contained',
    color: 'primary'
  });

  return (
    <Box component="header" className={classes.header}>
      <Hidden lgUp>
        <IconButton
          edge="start"
          className={classes.burgerButton}
          onClick={handleDrawerOpen}
        >
          <MenuIcon fontSize="large" />
        </IconButton>

        <Drawer
          open={drawerOpen}
          onClose={handleDrawerClose}
          PaperProps={{ classes: { root: classes.paper } }}
        >
          <div class={classes.drawerMenu}>
            <List>
              {[
                { title: 'Όλα', category: 'all' },
                { title: 'Ποδόσφαιρο', category: 'soccer' },
                { title: 'Μπάσκετ', category: 'basketball' },
                { title: 'Βόλεϊ', category: 'volleyball' },
                { title: 'Χαντμπολ', category: 'handball' },
                { title: 'Λοιπά', category: 'other' }
              ].map(item => (
                <ListItem
                  button
                  key={item.category}
                  component={Link}
                  to={`/${item.category === 'all' ? '' : item.category}`}
                >
                  <ListItemIcon classes={{ root: classes.listItemIcon }}>
                    {renderCategoryIcon(item.category)}
                  </ListItemIcon>
                  <ListItemText primary={item.title} />
                </ListItem>
              ))}
            </List>
          </div>
        </Drawer>
      </Hidden>

      <h1 className={classes.title}>nairepaok</h1>

      <Hidden smDown>
        <Toolbar className={classes.menu} component="nav">
          <Button {...getMenuButtonProps('/')}>ΟΛΑ</Button>
          <Button {...getMenuButtonProps('/soccer')}>ΠΟΔΟΣΦΑΙΡΟ</Button>
          <Button {...getMenuButtonProps('/basketball')}>ΜΠΑΣΚΕΤ</Button>
          <Button {...getMenuButtonProps('/volleyball')}>ΒΟΛΕΥ</Button>
          <Button {...getMenuButtonProps('/handball')}>ΧΑΝΤΜΠΟΛ</Button>
          <Button {...getMenuButtonProps('/other')}>ΛΟΙΠΑ</Button>
        </Toolbar>
      </Hidden>
    </Box>
  );
}

Header.propTypes = {};

Header.defaultProps = {};
