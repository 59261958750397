import React, { useState, useEffect, useRef, useMemo } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { Masonry, useInfiniteLoader } from 'masonic';
import Header from 'Components/Header';
import Ad from 'Components/Ad';
import NewsItem from 'Components/Item';
import { injectAds } from 'Library/utils/listings';
import { getListings } from 'Library/api/listings'; // eslint-disable-line import/no-webpack-loader-syntax
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
  },
}));

const renderByType = ({ data }) => {
  if (('type' in data && data.type === 'ad') || 'loading' in data) {
    return <Ad />;
  }
  return (
    <NewsItem
      {...data}
      description={data.desc}
      published_at={data.articleDate}
      link={data.url}
    />
  );
};

function App() {
  const classes = useStyles();

  const [listings, setListings] = useState([]);
  const requesting = useRef(false);

  const maybeLoadMore = useInfiniteLoader(async () => {
    if (requesting.current) {
      return;
    }
    requesting.current = true;
    const before = listings[listings.length - 1].articleDate;
    const { listings: newListings } = await getListings({
      before,
    });

    requesting.current = false;
    setListings((prevListings) => [
      ...prevListings.filter((l) => !('loading' in l)),
      ...newListings,
    ]);
  });

  useEffect(() => {
    async function loadMore() {
      const { listings } = await getListings();
      setListings(listings);
    }

    loadMore();
  }, []);

  const items = useMemo(() => listings, [listings]);
  /*
  useLayoutEffect(() => {
    setListings([]);
    setSearchParams((searchParams) => ({ ...searchParams, category }));
  }, [category]);
*/
  return (
    <Container className={classes.root}>
      <CssBaseline />
      <Header />
      <Masonry
        onRender={maybeLoadMore}
        items={items}
        columnGutter={8}
        columnWidth={275}
        overscanBy={1.1}
        render={renderByType}
      />
    </Container>
  );
}

export default App;
