import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import arenafm from './assets/arenafm.png';
import contra from './assets/contra.png';
import gazzetta from './assets/gazzetta.png';
import inpaok from './assets/inpaok.png';
import metrosport from './assets/metrosport.png';
import novasports from './assets/novasports.png';
import paok24 from './assets/paok24.png';
import paokvoice from './assets/paokvoice.png';
import sport24 from './assets/sport24.png';
import sportdog from './assets/sportdog.png';

export default function SourceLogo({ source }) {
  switch (source) {
    case 'arenafm.gr':
      return <Avatar src={arenafm} />;
    case 'contra.gr':
      return <Avatar src={contra} />;
    case 'gazzetta.gr':
      return <Avatar src={gazzetta} />;
    case 'inpaok.com':
      return <Avatar src={inpaok} />;
    case 'sport24.gr':
      return <Avatar src={sport24} />;
    case 'metrosport.gr':
      return <Avatar src={metrosport} />;
    case 'novasports.gr':
      return <Avatar src={novasports} />;
    case 'paok24.com':
      return <Avatar src={paok24} />;
    case 'paokvoice.com':
      return <Avatar src={paokvoice} />;
    case 'sportdog.gr':
      return <Avatar src={sportdog} />;
    default:
      return <Avatar>{source.charAt(0).toUpperCase()}</Avatar>;
  }
}
